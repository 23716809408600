import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'reactstrap';
import Cookies from 'js-cookie';

import LogInForm from '../../../containers/LogIn/components/LogInForm';
import RegistrationForm from '../../../containers/LogIn/components/RegistrationForm';
import ReactGA from 'react-ga';
import GoogleButton from '../../GoogleButton';
import ResetPasswordForm from '../../../containers/LogIn/components/ResetPasswordForm';
import { TrackGoogleAnalyticsEvent } from '../../utils/google-analytics';

const LoginModal = (props) => {
  const { visible, userState, formData } = props

  const [formType, setFormType] = useState('register');
  const [alertOpen, setAlertOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState((<><h3>Create a Free Account</h3><p>No CC Required</p></>) );

  let apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'https://dashboard-api-test.opengraph.io';
  const renderForm = () => {
    if (formType === 'login') {
      return <LogInForm switchToPasswordResetForm={switchToResetForm()} />
    } else if(formType === 'resetpassword') {
      return <ResetPasswordForm formData={formData.formData} />
    } else {
      return <RegistrationForm flipForm={flipForm} />
    }
  }

  const switchToResetForm = () => {
    setFormType('resetpassword')
  }

  const signInToGoogle = () => {
    ReactGA.event({
      category: 'onboard',
      action: 'login',
      label: 'google'
    });
  };

  const handleFormChange = () => {
    if (formType === 'login') {
      return () => {
        setFormType('register')
        setModalTitle((<><h3>Create a Free Account</h3><p>No CC Required</p></>))
      }
    } else {
      return () => {
        setFormType('login')
        setModalTitle((<><h3>Sign in to use the Debugger</h3></>))
      }
    }
  }

  const flipForm = () => {
    if (formType === 'login') {
      return () => {
        setFormType('register')
        setModalTitle((<><h3>Create a Free Account</h3><p>No CC Required</p></>))
      }
    } else {
      return () => {
        setFormType('login')
        setModalTitle((<><h3>Sign in to use the Debugger</h3></>))
      }
    }
  }

  const renderButtonLabel = () => {
    if (formType === 'login') {
      return 'Register'
    } else if (formType === 'resetpassword') {
      return 'Back to Sign In'
    } else {
      return 'Already Have an Account'
    }
  }

  const handleSignInButtonClick = () => {
    TrackGoogleAnalyticsEvent('googleSignInOrSignUp', 'Sign Up Flow', 'googleSignIn', 'login_register')
    const oneMinuteFromNow = new Date(new Date().getTime() + 60 * 1000);

    Cookies.set('location', 'debug', { expires: oneMinuteFromNow, path: '/', domain: 'opengraph.io' });
    window.location.href = apiUrl + '/auth/google';
  }

  const handleAlert = () => {
    setAlertOpen(!alertOpen)
  }

  useEffect(() => {
    if(userState.alert) {
      setAlertOpen(true)
    }
  }, [userState.alert]);

  return (
    <Modal isOpen={visible} className={'login__modal supportModal modal-dialog--primary paymentModal '}>
      {alertOpen ? <Alert color={userState.alert.color} className="alert--bordered" isOpen={alertOpen} toggle={handleAlert}>
        <div className={'padding5'}>
                    <span>
                      {userState.alert.message}
                    </span>
        </div>
      </Alert> : modalTitle }
      {userState.loading ? (
        <div style={{ minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className="spinner-border text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div> </div> ) :
        <>
        {
          formType === 'login' ? <LogInForm modal switchToPasswordResetFormModal={() => switchToResetForm()} /> : formType === 'resetpassword' ? <ResetPasswordForm formData={formData.formData} /> : <RegistrationForm flipForm={flipForm} location={'debug'} />
        }
          <div style={{ marginTop: 'unset' }} className="account__or-modal">
            <p>OR</p>
          </div>
        <Button disabled={userState.loading} color={'link'} siz={'sm'} className="btn btn-outline-primary account__btn account__btn--small"
              onClick={handleFormChange()}>{renderButtonLabel()}</Button>
      <div className="account__social">

        <button style={{ border: 'none', width: '100%', background: 'none', padding: 0}} onClick={handleSignInButtonClick}>
          <GoogleButton/>
        </button>
      </div>
        </>
      }
    </Modal>

  )
};

export default LoginModal;
