import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
import { getLoggedInUser } from '../../redux/actions/users';
import { withRouter } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';

const MainWrapper = ({ theme, children, getLoggedInUser, user }) => {

  const [ appLoading, setAppLoading ] = React.useState(true);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const fetchUser = async () => {
     await getLoggedInUser();
     setAppLoading(false)
    }

    if(location.search.includes('emailVerified=true')) {
      TrackGoogleAnalyticsEvent('verifyEmail', 'Sign Up Flow', 'verifyEmail', 'sign_up_flow')
      // then remove the query string
      history.push(location.pathname);
    }

    fetchUser();
  }, []);

  useEffect(() => {
    if(user) {
      const intercomUser = {
        app_id: 'm374hgng',
        email: user.email
      }

      window.Intercom('boot', intercomUser);
    }
  }, [ user ]);

  return (
    <div className={theme.className}>
      <div className="wrapper">
        { appLoading ? (<div className="spinner-border text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div> ) : children }
      </div>
    </div>
  );
};

MainWrapper.propTypes = {
  theme: ThemeProps.isRequired,
  children: PropTypes.element.isRequired,
  getLoggedInUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.users.user,
});

const mapDispatchToProps = (dispatch) => ({
  getLoggedInUser: () => dispatch(getLoggedInUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainWrapper));
